import { create } from "zustand";
import { MeasurementPointState } from "../models";
import { getMeterMeasurementPointService } from "../services";

export const useMeasurementPointState = create<MeasurementPointState>()(
    (set) => ({
        loading: false,
        error: false,
        measurementPoints: [],
        totalMeasurementPoints: 0,
        getMeterMeasurementPoint: async ({
            meterId,
            page,
            size,
        }: {
            meterId: string;
            page: number;
            size?: number;
        }) => {
            set({ loading: true, error: false });
            try {
                const res = await getMeterMeasurementPointService({
                    meterId,
                    page,
                    size,
                });
                set({
                    measurementPoints: res.items,
                    totalMeasurementPoints: res.total,
                });
            } catch (_error) {
                set({ error: true });
            } finally {
                set({ loading: false });
            }
        },
    }),
);
