import { hot } from "react-hot-loader/root";
import MainLayout from "./layouts/main-layout";
import { api, initEnviroment, ThemeConfig } from "@enerbit/base";
import MetersRouter from "./router/router";

import { PrimeReactProvider } from "primereact/api";
import { SWRConfig } from "swr";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

const fetcher = (url: string) => api.get(url).then((res) => res.data);

export default hot(function Root() {
    return (
        <ThemeConfig>
            <PrimeReactProvider>
                <SWRConfig
                    value={{
                        fetcher,
                        keepPreviousData: true,
                    }}
                >
                    <MainLayout>
                        <MetersRouter />
                    </MainLayout>
                </SWRConfig>
            </PrimeReactProvider>
        </ThemeConfig>
    );
});
