import { Box, Grid } from "@enerbit/base";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DetailHeader from "./components/detail-header";
import Actions from "./components/actions";
import { useMetersState } from "../../store";
import GeneralInfo from "./components/general-info";
import MultipliersHistory from "./components/multipliers-history";
import MeterUsages from "./components/meter-usages";
import SectionContainer from "../../components/container/section-container";
import { StyledTab } from "../../components/Tabs/styled-tab";
import { StyledTabs } from "../../components/Tabs/styled-tabs";
import { TabPanel } from "../../components/Tabs/tab-panel";
import MeasurementOverview from "./components/measurements-overview";
import MeasurementPointHistory from "./components/measurement-point-history";
import MetersRelationships from "./components/meters-relationships";
import MeterEvents from "./components/meter-events";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const MeterDetail = () => {
    const { id } = useParams<{ id: string }>();
    const [value, setValue] = useState<number>(0);
    const { getMeter } = useMetersState((state) => state);

    const navigate = useNavigate();

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!id) {
            navigate("/");
        } else {
            getMeter(id);
        }
    }, [id]);

    return (
        <>
            {id && (
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <DetailHeader id={id} />
                        <Actions />
                    </Box>
                </Box>
            )}
            <Grid container columnSpacing={3} sx={{ mt: 3 }}>
                <Grid item xs={8.5}>
                    <Box>
                        <GeneralInfo />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <MultipliersHistory />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <MeterUsages />
                    </Box>
                    <SectionContainer sx={{ mt: 2 }}>
                        <Box>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                }}
                            >
                                <StyledTabs
                                    value={value}
                                    onChange={handleChange}
                                >
                                    <StyledTab
                                        label={<Box>Historial de eventos</Box>}
                                        {...a11yProps(0)}
                                    />
                                </StyledTabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Box sx={{ my: 2 }}>
                                    <MeterEvents />
                                </Box>
                            </TabPanel>
                        </Box>
                    </SectionContainer>
                </Grid>
                <Grid item xs={3.5}>
                    <Box sx={{ mb: 2 }}>
                        <MeasurementOverview />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <MeasurementPointHistory />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <MetersRelationships />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default MeterDetail;
