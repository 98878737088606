import { api } from "@enerbit/base";
import { SearcMeasurementPointResponse } from "../models";

export const getMeterMeasurementPointService = async ({
    meterId,
    page,
    size,
}: { meterId: string; page: number; size?: number }) => {
    try {
        const params: Record<string, string | number | boolean> = {
            meter_id: meterId,
            size: size ?? 50,
            page,
        };
        const res = await api.get<SearcMeasurementPointResponse>(
            "/odin/measurement-point/measurement-point/meters-relationships",
            { params },
        );

        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingMeasurementPoint");
    }
};
