import { HashRouter, Routes, Route } from "react-router-dom";
import MetersList from "../pages/meters-list";
import MeterDetail from "../pages/meter-detail";

const MetersRouter = () => {
    return (
        <HashRouter basename="/meters">
            <Routes>
                <Route path="/" element={<MetersList />} />
                <Route path="/:id" element={<MeterDetail />} />
            </Routes>
        </HashRouter>
    );
};

export default MetersRouter;
