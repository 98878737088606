import { create } from "zustand";
import { IEvents, ListMeterEventsParams } from "../models";
import { getEventsService } from "../services";

interface EventsStore {
    loading: boolean;
    error: boolean;
    currentPage: number;
    totalPages: number;
    events: IEvents[];
    getMeterEvents: (props: ListMeterEventsParams) => Promise<void>;
}

export const useEventsStore = create<EventsStore>()((set) => ({
    loading: false,
    error: false,
    currentPage: 0,
    totalPages: 0,
    events: [],
    getMeterEvents: async ({ serial, page }: ListMeterEventsParams) => {
        try {
            set({ loading: true, error: false });
            const events = await getEventsService({ serial, page });
            set({
                events: events.items,
                currentPage: events.page,
                totalPages: events.pages,
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
