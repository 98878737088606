import { Box, styled } from "@enerbit/base";

interface Props {
    bgcolor: string | undefined;
    fontcolor: string | undefined;
}

const StatusTag = styled(Box)<Props>(({ bgcolor, fontcolor }) => ({
    borderRadius: "8px",
    padding: "0px 16px",
    backgroundColor: bgcolor || "transparent", // Fondo predeterminado
    color: fontcolor || "#000", // Color de texto predeterminado
    display: "inline-block", // Estilo inline-block
    textAlign: "center", // Alineación horizontal
    lineHeight: "32px", // Altura de línea igual a la altura para centrado vertical
    height: "32px", // Altura fija
    verticalAlign: "middle", // Alineación vertical si está en un flujo de texto
}));

export default StatusTag;
