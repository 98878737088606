import { enerbitColors } from "@enerbit/base";
import { IEvents } from "../models";

const relayOn = '"reason":"ON","type":"RELAY"';
const relayOff = '"reason":"OFF","type":"RELAY"';
const tariffPlant =
    '"type":"PROMETHEUS","reason":"TARIFF_CHANGED","tariff":"PLANT"';
const tamper = '"type":"TAMPER"';

export const metersEventsTypes: Record<string, string> = {
    "-1": "Error credenciales -1",
    "-2": "Error cableado -2",
    "-3": "Error de comunicación -3",
    "-4": "Error de comunicación -4",
    "-5": "Error de comunicación -5",
    [relayOn]: "Relay on",
    [relayOff]: "Relay off",
    [tariffPlant]: "Tarifa planta",
    [tamper]: "Manipulación tapa bornera",
};

export const formatEventLog = (event: IEvents) => {
    const message = formatMeterEventMessage(event);
    const isError = event.event_types.event === "Error";

    return { message, isError };
};

const formatMeterEventMessage = (event: IEvents) => {
    const eventPayload = event.event_payload;

    switch (event.event_types.code) {
        case "0":
            const formattedPayload = JSON.parse(eventPayload);
            return metersEventsTypes[formattedPayload.err];
        case "1":
            return getEventName(eventPayload) == ""
                ? "Evento desconocido"
                : getEventName(eventPayload);
        case "2":
            return "Reloj sincronizado";
        case "Ocurrence":
            return "";
        case "8":
            return "Remote supply connection";
        case "9":
            return "Remote supply diconnection";
        case "10":
            return "Load Profile 1 write channels operation";
        case "11":
            return "Load Profile 2 write channels operation";
        case "12":
            return "Load Profile 1 write capture period operation";
        case "13":
            return "Load Profile 2 write capture period operation";
        case "14":
            return getEventName(eventPayload) == ""
                ? "Evento desconocido"
                : getEventName(eventPayload);
        case "18":
            return "Outage event";
        case "19":
            return "Restore event";
        default:
            return "Evento desconocido";
    }
};

export const getEventName = (payload: string) => {
    let eventName: string = "";

    Object.keys(metersEventsTypes).forEach((key) => {
        if (payload.includes(key)) {
            eventName = metersEventsTypes[key];
        }
    });

    return eventName;
};


export const getEventCategory = (type: string) => {
    switch (type) {
        case "Error":
            return {
                bgcolor: enerbitColors.error[100],
                fontColor: enerbitColors.error.main,
                label: "Crítico",
            };
        case "Status":
            return {
                bgcolor: enerbitColors.warning[100],
                fontColor: enerbitColors.warning.main,
                label: "Preventivo",
            };
        default:
            return {
                bgcolor: "#EAF0FA",
                fontColor: "#3B82F6",
                label: "Informativo",
            };
    }
};