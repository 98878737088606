import StyledModal from "../../../components/container/styled-modal";
import { Box, CustomAlert, Pagination, Skeleton, Stack } from "@enerbit/base";
import { generateRandomId } from "../../../utils";
import MultiplierItem from "./multiplier-item";
import PaginationContainer from "../../../components/container/pagination-container";
import { useEffect, useState } from "react";
import { IPaginatedResponse, Multipliers } from "../../../models";
import { getMeterMultipliersService, getMeterService } from "../../../services";

interface Props {
    open: boolean;
    handleClose: () => void;
    id: string;
}

const MultipliersModal = ({ open, handleClose, id }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [multipliers, setMultipliers] =
        useState<IPaginatedResponse<Multipliers>>();

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        fetchMultipliers(value - 1);
    };

    const fetchMultipliers = async (page: number) => {
        setError(false);
        setLoading(true);
        try {
            const tMeter = await getMeterService(id);
            const data = await getMeterMultipliersService({
                serial: tMeter.serial,
                page,
                size: 5,
            });
            setMultipliers(data);
        } catch (_error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            fetchMultipliers(0);
        }
    }, [open, id]);

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            styles={{ width: "900px" }}
            title="Historial de multiplicadores"
        >
            {loading && (
                <>
                    {[...Array(7)].map(() => (
                        <Skeleton
                            variant="rectangular"
                            key={generateRandomId()}
                            height={56}
                            sx={{ mb: 2 }}
                        />
                    ))}
                </>
            )}
            {!loading && error && (
                <CustomAlert
                    severity="error"
                    text={"Error cargando historial de multiplicadores"}
                    onClose={() => {}}
                />
            )}
            {!loading && !error && multipliers && (
                <>
                    {multipliers.items.map((m) => (
                        <Box sx={{ mb: 2 }} key={m.id}>
                            <MultiplierItem multiplier={m} />
                        </Box>
                    ))}
                    <PaginationContainer sx={{ mt: 1 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={multipliers.page + 1}
                                count={multipliers.pages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
        </StyledModal>
    );
};

export default MultipliersModal;
