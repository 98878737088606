import { create } from "zustand";
import { Meter } from "../models";
import {
    getMeterDeviceRelationshipService,
    getMeterService,
} from "../services";
import { Edge, Node } from "@xyflow/react";
import { generateMeterDeviceTree } from "../utils/meters";

interface DeviceStore {
    loading: boolean;
    error: boolean;
    totalMeters: number;
    getMeterRelationships: (props: {
        meterId: string;
        page: number;
        size?: number;
    }) => Promise<void>;
    nodes: Node[];
    edges: Edge[];
    meters: Meter[];
}

export const useDeviceStore = create<DeviceStore>()((set) => ({
    loading: false,
    error: false,
    nodes: [],
    edges: [],
    meters: [],
    totalMeters: 0,
    getMeterRelationships: async ({
        page,
        meterId,
        size,
    }: {
        meterId: string;
        page: number;
        size?: number;
    }) => {
        set({ loading: true, error: false });
        try {
            const relationships = await getMeterDeviceRelationshipService({
                meterId,
                page,
                size,
            });
            const metersRequest = relationships.items.map(async (meter) => {
                const res = await getMeterService(meter.meter_id);

                return res;
            });

            const meters = await Promise.all(metersRequest);

            const { nodes, edges } = generateMeterDeviceTree({
                meters,
                device: relationships.items[0].device_meter,
            });

            set({
                nodes,
                edges,
                meters,
                totalMeters: relationships.total,
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
