import {
    ArrowForwardIcon,
    Box,
    ContentCopyIcon,
    enerbitColors,
    IconButton,
    Typography,
} from "@enerbit/base";
import { Handle, Position } from "@xyflow/react";
import { navigateToUrl } from "single-spa";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function SmartbitNode({ data, width }: any) {
    const handleStyle = { left: 15 };

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <>
            <Box
                sx={{
                    width: width,
                    padding: "12px",
                    borderRadius: "4px",
                    border: `1px solid ${enerbitColors.primary[200]}`,
                    backgroundColor: enerbitColors.primary[100],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        minWidth: 0,
                        flex: 1,
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={() => {
                            handleCopy(data.id);
                        }}
                    >
                        <ContentCopyIcon fontSize="small" color="primary" />
                    </IconButton>
                    <Box
                        sx={{
                            minWidth: 0,
                            flex: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: enerbitColors.neutral[700],
                            }}
                        >
                            smartBit
                        </Typography>
                        <Typography
                            sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                            }}
                        >
                            {data.id}
                        </Typography>
                    </Box>
                </Box>
                <IconButton
                    sx={{
                        backgroundColor: "#FF7705",
                        borderRadius: "12px",
                        height: "32px",
                        width: "32px",
                        ":hover": {
                            backgroundColor: "#FF7705",
                        },
                    }}
                    onClick={() => {
                        navigateToUrl(`/#/smartbits/${data.id}`);
                    }}
                >
                    <ArrowForwardIcon sx={{ color: "white" }} />
                </IconButton>
            </Box>
            <Handle
                type="source"
                position={Position.Bottom}
                id="a"
                style={handleStyle}
            />
        </>
    );
}

export default SmartbitNode;
