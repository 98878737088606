import StyledModal from "../../../components/container/styled-modal";
import { Box, Pagination, Stack } from "@enerbit/base";
import { useDeviceStore } from "../../../store";
import MeterItem from "./meter-item";
import { useMemo, useState } from "react";
import PaginationContainer from "../../../components/container/pagination-container";

interface Props {
    open: boolean;
    handleClose: () => void;
    id: string;
}

const ELEMENTS_PER_PAGE = 5;

const MetersModal = ({ open, handleClose }: Props) => {
    const { meters } = useDeviceStore((state) => state);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const startIndex = useMemo(() => {
        return (currentPage - 1) * ELEMENTS_PER_PAGE;
    }, [currentPage]);

    const endIndex = useMemo(() => {
        return startIndex + ELEMENTS_PER_PAGE;
    }, [currentPage]);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        setCurrentPage(value);
    };

    return (
        <StyledModal open={open} onClose={handleClose} title="Otros medidores">
            {meters.slice(startIndex, endIndex).map((meter) => (
                <Box key={meter.id} sx={{ mb: 1 }}>
                    <MeterItem meter={meter} />
                </Box>
            ))}
            <PaginationContainer>
                <Stack spacing={2}>
                    <Pagination
                        page={currentPage}
                        count={Math.round(meters.length / ELEMENTS_PER_PAGE)}
                        shape="rounded"
                        onChange={handlePagination}
                    />
                </Stack>
            </PaginationContainer>
        </StyledModal>
    );
};

export default MetersModal;
