import {
    ArrowForwardIcon,
    Box,
    ContentCopyIcon,
    enerbitColors,
    Grid,
    IconButton,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { Meter } from "../../../models";
import { useNavigate } from "react-router-dom";

interface Props {
    meter: Meter;
}

const MeterItem = ({ meter }: Props) => {
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        // setTimeout(() => {}, 2000);
    };

    const navigate = useNavigate();
    const goToMeterDetail = (id: string) => {
        navigate(`/${id}`);
    };

    return (
        <SectionContainer>
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={5.5}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleCopy(`${meter.serial}`);
                            }}
                        >
                            <ContentCopyIcon fontSize="small" color="primary" />
                        </IconButton>
                        <Box>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral.main,
                                    fontSize: "12px",
                                }}
                            >
                                Serial
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: enerbitColors.neutral[900],
                                }}
                            >
                                {meter.serial}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={5.5}>
                    <Typography
                        sx={{
                            color: enerbitColors.neutral.main,
                            fontSize: "12px",
                        }}
                    >
                        Modelo
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            color: enerbitColors.neutral[900],
                        }}
                    >
                        {meter.meter_model.name}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        sx={{
                            backgroundColor: "#FF7705",
                            borderRadius: "12px",
                            height: "32px",
                            width: "32px",
                            ":hover": {
                                backgroundColor: "#FF7705",
                            },
                        }}
                        onClick={() => {
                            goToMeterDetail(meter.id);
                        }}
                    >
                        <ArrowForwardIcon sx={{ color: "white" }} />
                    </IconButton>
                </Grid>
            </Grid>
        </SectionContainer>
    );
};

export default MeterItem;
