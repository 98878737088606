import {
    Box,
    Button,
    CachedIcon,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    SearchIcon,
    SelectChangeEvent,
    theme,
    Typography,
    useMediaQuery,
} from "@enerbit/base";
import { Filters } from "../../../models";
import CustomTextField from "../../../components/Inputs/custom-textfield";
import CustomSelect from "../../../components/Inputs/custom-select";
import { eventValues } from "../../../common";

interface Props {
    filters: Filters;
    handleFilterChange: (key: string, value: unknown) => void;
    handleSearch: () => void;
    handleReset: () => void;
}

const EventsFilters = ({
    filters,
    handleFilterChange,
    handleSearch,
    handleReset,
}: Props) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: isSmallScreen ? "stretch" : "end",
                gap: 2,
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    gap: 2,
                    flex: 1,
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <InputLabel sx={{ mb: 1 }}>Buscar por</InputLabel>
                    <FormControl fullWidth>
                        <CustomTextField
                            fullWidth
                            value={filters.name}
                            placeholder="Buscar por serial"
                            onChange={(e) =>
                                handleFilterChange("name", e.target.value)
                            }
                            InputProps={{
                                startAdornment: <SearchIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <InputLabel sx={{ mb: 1 }}>Evento</InputLabel>
                    <FormControl fullWidth>
                        <CustomSelect
                            fullWidth
                            displayEmpty
                            multiple
                            defaultValue={[]}
                            value={filters["event_types"]}
                            onChange={(
                                event: SelectChangeEvent<unknown>,
                                _child: React.ReactNode,
                            ) => {
                                handleFilterChange("event_types", [
                                    ...(event.target.value as string[]),
                                ]);
                            }}
                            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                            renderValue={(selected: any) => {
                                if (
                                    !selected ||
                                    selected.length === 0 ||
                                    (selected.length === 1 &&
                                        selected[0] === "")
                                ) {
                                    return (
                                        <Typography
                                            sx={{
                                                color: "rgba(0, 0, 0, 0.42)",
                                            }}
                                        >
                                            Seleccionar tipos de evento
                                        </Typography>
                                    );
                                }
                                return selected.join(", ");
                            }}
                        >
                            <MenuItem disabled value="">
                                <Typography
                                    sx={{ color: "rgba(0, 0, 0, 0.42)" }}
                                >
                                    Seleccionar tipos de evento
                                </Typography>
                            </MenuItem>
                            {eventValues.map((ev, index) => (
                                <MenuItem
                                    key={`select-event-item-${index + 1}`}
                                    value={ev}
                                >
                                    <Checkbox
                                        checked={(
                                            filters["event_types"] as string[]
                                        ).includes(ev)}
                                    />
                                    <ListItemText primary={ev} />
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    gap: 2,
                    alignItems: "center",
                    mt: isSmallScreen ? 2 : 0,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    fullWidth={isSmallScreen}
                >
                    <SearchIcon />
                </Button>
                <IconButton
                    color="primary"
                    onClick={handleReset}
                    sx={{
                        width: isSmallScreen ? "100%" : "auto",
                        justifyContent: "center",
                    }}
                >
                    <CachedIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default EventsFilters;
