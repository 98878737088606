import { create } from "zustand";
import { Multipliers } from "../models";
import { getMeterMultipliersService, getMeterService } from "../services";

interface MultipliersStore {
    loading: boolean;
    error: boolean;
    multipliers: Multipliers[];
    currentPage: number;
    totalPages: number;
    totalMultipliers: number;
    getMeterMultipliers: (props: { id: string; page: number, size?: number  }) => Promise<void>;
}

export const useMultipliersStore = create<MultipliersStore>()((set) => ({
    loading: false,
    error: false,
    multipliers: [],
    currentPage: 0,
    totalPages: 0,
    totalMultipliers: 0,
    getMeterMultipliers: async ({
        id,
        page,
        size,
    }: { id: string; page: number; size?: number }) => {
        set({ loading: true, error: false });
        try {
            const meter = await getMeterService(id);
            const { serial } = meter;
            const multipliers = await getMeterMultipliersService({
                serial,
                page,
                size
            });

            set({
                multipliers: multipliers.items,
                totalPages: multipliers.pages,
                totalMultipliers: multipliers.total,
                currentPage: multipliers.page,
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
