import { Edge, Node } from "@xyflow/react";
import { DeviceMeter, Meter } from "../models";

export const generateMeterDeviceTree = ({
    meters,
    device,
}: {
    meters: Meter[];
    device: DeviceMeter;
}) => {
    const deviceNode: Node = {
        id: device.id,
        position: { x: 0, y: 0 },
        data: { id: device.id },
        type: "smartBit",
    };

    const meterNodes: Node[] = meters.map((meter, i) => {
        return {
            id: meter.id,
            position: { x: 60, y: i * 80 + 80 },
            data: { serial: `${meter.serial}/${meter.meter_model.name}`, id: meter.id },
            type: "meter",
        };
    });

    const edges: Edge[] = meterNodes.map((node) => ({
        id: `${deviceNode.id}-${node.id}`,
        source: deviceNode.id,
        target: node.id,
        type: "step",
        animated: true,
    }));

    const nodes: Node[] = [deviceNode, ...meterNodes];

    return { nodes, edges };
};
