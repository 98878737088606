import { api } from "@enerbit/base";
import {
    GetMultipliersParams,
    IPaginatedResponse,
    Multipliers,
} from "../models";

export const getMeterMultipliersService = async ({
    serial,
    page,
    size,
}: GetMultipliersParams) => {
    const params: Record<string, string | number | boolean> = {
        meter_serial: serial,
        page,
        size: size ?? 50,
    };
    try {
        const res = await api.get<IPaginatedResponse<Multipliers>>(
            "/odin/meters/multipliers",
            { params },
        );
        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingMultipliers");
    }
};
