import { Box, Button } from "@enerbit/base";
import { useState } from "react";
import { navigateToUrl } from "single-spa";
import RestoreModal from "./restore-modal";

const Actions = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const goToOrders = () => {
        navigateToUrl("/#/orders");
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={goToOrders}
                >
                    Generar OS
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setOpenModal(true)}
                >
                    Recuperación
                </Button>
            </Box>
            <RestoreModal isOpen={openModal} handleClose={handleCloseModal} />
        </>
    );
};

export default Actions;
