import { create } from "zustand";
import { getMeterService, getSchedulesService } from "../services";
import { EUnits, AdaptedSchedules } from "../models";
import { formatUsages } from "../utils/usages";
import moment from "moment";
import { DATE_TIME_INPUT_FORMAT } from "../common";

interface SchedulesStore {
    loading: boolean;
    error: boolean;
    currentUnits: EUnits;
    getUsages: (props: {
        since: string;
        until: string;
        id: string;
    }) => Promise<void>;
    usages: Record<string, Map<string, AdaptedSchedules[]>> | null;
    setCurrentUnits: (units: EUnits) => void;
}

export const useSchedules = create<SchedulesStore>()((set) => ({
    loading: false,
    error: false,
    usages: null,
    currentUnits: EUnits.Wh,
    getUsages: async ({
        id,
        since,
        until,
    }: { since: string; until: string; id: string }) => {
        set({ loading: true, error: false });
        try {
            const meter = await getMeterService(id);
            const schedules = await getSchedulesService({
                serial: meter.serial,
                since,
                until,
            });

            const formattedUsages = formatUsages(
                schedules.map((sch) => ({
                    start: moment(sch.time_local_utc)
                        .utcOffset(-5)
                        .format(DATE_TIME_INPUT_FORMAT),
                    end: "",
                    active_energy_exported: sch.active_energy_exported,
                    active_energy_imported: sch.active_energy_imported,
                    reactive_energy_exported: sch.reactive_energy_exported,
                    reactive_energy_imported: sch.reactive_energy_imported,
                    is_estimated: sch.is_estimated,
                })),
            );
            const mappedUsages: Record<
                string,
                Map<string, AdaptedSchedules[]>
            > = {};

            const keys = Object.keys(formattedUsages);

            keys.map((key) => {
                const tempMap = new Map<string, AdaptedSchedules[]>();
                const hours = Object.keys(formattedUsages[key]).sort(
                    (a, b) => parseInt(a, 10) - parseInt(b, 10),
                );
                hours.map((h) => {
                    tempMap.set(h, formattedUsages[key][h]);
                });

                mappedUsages[key] = tempMap;
            });

            // console.log("mapped usages: ", mappedUsages);

            set({ usages: mappedUsages });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
    setCurrentUnits: (units: EUnits) => {
        set({ currentUnits: units });
    },
}));
