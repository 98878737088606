import { api } from "@enerbit/base";
import { AdaptedSchedules, GetSchedulesParams, Schedules } from "../models";

export const getMeterUsagesService = async ({
    serial,
    since,
    until,
}: GetSchedulesParams) => {
    try {
        const params = {
            since,
            until,
            period_number: 15,
            period_str: "minute",
        };
        const res = await api.get<AdaptedSchedules[]>(
            `/odin/meters/usages/${serial}`,
            {
                params,
            },
        );
        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingUsages");
    }
};

export const getSchedulesService = async ({
    serial,
    since,
    until,
}: GetSchedulesParams) => {
    try {
        const params = {
            since,
            until,
            hour: false,
        };
        const res = await api.get<Schedules[]>(
            `/odin/meters/schedules/measurements/${serial}`,
            {
                params,
            },
        );
        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingUsages");
    }
};
