import { enerbitColors } from "@enerbit/base";
import { MeterState } from "../models";

export const meterStatusColors: Record<
    MeterState,
    { bgcolor?: string; fontcolor?: string }
> = {
    [MeterState.PerActive]: { bgcolor: "#E0F2FF", fontcolor: "#3892F3" },
    [MeterState.Active]: {
        bgcolor: enerbitColors.success[100],
        fontcolor: enerbitColors.success.main,
    },
    [MeterState.Activated]: {
        bgcolor: enerbitColors.success[100],
        fontcolor: enerbitColors.success.main,
    },
    [MeterState.Store]: {
        bgcolor: enerbitColors.neutral[100],
        fontcolor: enerbitColors.neutral.main,
    },
    [MeterState.Lab]: {
        bgcolor: enerbitColors.neutral[100],
        fontcolor: enerbitColors.neutral.main,
    },
    [MeterState.Retired]: {
        bgcolor: enerbitColors.error[100],
        fontcolor: enerbitColors.error.main,
    },
    [MeterState.Maintenance]: {
        bgcolor: enerbitColors.warning[100],
        fontcolor: enerbitColors.warning.main,
    },
};

export const ORDER_TYPES_TO_NAMES: Record<
    string,
    { label: string; font: string | undefined; bgcolor: string | undefined }
> = {
    pending: {
        label: "Pendiente",
        font: enerbitColors.warning.main,
        bgcolor: enerbitColors.warning[100],
    },
    done: {
        label: "Completada",
        font: enerbitColors.success.main,
        bgcolor: enerbitColors.success[100],
    },
    cancel: {
        label: "Cancelada",
        font: enerbitColors.error.main,
        bgcolor: enerbitColors.error[100],
    },
    partial_done: {
        label: "Parcialmente completada",
        font: "#FFF",
        bgcolor: "#3B82F6",
    },
    pending_assignment: {
        label: "Pendiente por asignar",
        font: "#667085",
        bgcolor: "#E4E7EC",
    },
};
