export interface GetSchedulesParams {
    serial: string;
    since: string;
    until: string;
}

export enum SchedulesStatus {
    EXISTS = "exists",
    MISSING = "missing",
    FUTURE = "future",
    ESTIMATED = "estimated",
}

export enum EUnits {
    Wh = "Wh",
    kWh = "kWh",
}

export interface Schedules {
    active_energy_exported: number | null;
    active_energy_imported: number | null;
    created_at: string;
    current_multiplier: number;
    is_estimated: boolean;
    meter_model: string;
    meter_serial: string;
    reactive_energy_exported: number | null;
    reactive_energy_imported: number | null;
    time_local_utc: string;
    time_sender: string;
    time_zone: string;
    voltage_multiplier: number;
}

export interface AdaptedSchedules {
    active_energy_exported: number | null;
    active_energy_imported: number | null;
    end: string;
    is_estimated: boolean;
    reactive_energy_exported: number | null;
    reactive_energy_imported: number | null;
    start: string;
    status?: SchedulesStatus;
}
