import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Divider,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { useMetersState, useMultipliersStore } from "../../../store";
import { useEffect, useState } from "react";
import MultiplierItem from "./multiplier-item";
import MultipliersModal from "./multipliers-modal";

const MultipliersHistory = () => {
    const {
        loading,
        error,
        totalMultipliers,
        multipliers,
        getMeterMultipliers,
    } = useMultipliersStore((state) => state);
    const {
        loading: loadingMeter,
        error: errorMeter,
        meter,
    } = useMetersState((state) => state);

    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (meter) {
            getMeterMultipliers({ id: meter.id, page: 0 });
        }
    }, [meter]);

    return (
        <>
            <SectionContainer>
                <Typography
                    sx={{
                        fontWeight: 700,
                        color: enerbitColors.primary.main,
                        fontSize: "18px",
                    }}
                >
                    Historial de multiplicadores
                </Typography>
                <Divider sx={{ my: 1, mx: "-12px" }} />

                {(loading || loadingMeter) && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}

                {(error || errorMeter) && (
                    <CustomAlert
                        severity="error"
                        text="Error consultando historial de multiplicadores"
                        onClose={() => {}}
                    />
                )}
                {!error &&
                    !loading &&
                    !loadingMeter &&
                    !errorMeter &&
                    totalMultipliers === 0 &&
                    meter && (
                        <CustomAlert
                            severity="info"
                            text="No se encontraron multiplicadores asociados a este medidor"
                            onClose={() => {}}
                        />
                    )}

                {!error && !loading && totalMultipliers > 0 && (
                    <>
                        {multipliers
                            .sort((a, b) => Number(!!a.end) - Number(!!b.end))
                            .slice(0, 3)
                            .map((m) => (
                                <Box sx={{ mt: 2 }} key={m.id}>
                                    <MultiplierItem multiplier={m} />
                                </Box>
                            ))}
                        {totalMultipliers > 3 && (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mt: 2,
                                }}
                            >
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => setOpen(true)}
                                >
                                    Ver más
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </SectionContainer>
            {open && meter && (
                <MultipliersModal
                    open={open}
                    handleClose={() => {
                        setOpen(false);
                    }}
                    id={meter.id}
                />
            )}
        </>
    );
};

export default MultipliersHistory;
