import {
    Box,
    Typography,
    enerbitColors,
    IconButton,
    ArrowForwardIcon,
    ContentCopyIcon,
} from "@enerbit/base";
import { MeasurementPoint } from "../../../models";
import { navigateToUrl } from "single-spa";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

interface Props {
    mp: MeasurementPoint;
}

const MeasurementPointItem = ({ mp }: Props) => {
    const goToMpDetail = (id: string) => {
        navigateToUrl(`/#/measurement-point/${id}`);
    };

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Box
            sx={{
                padding: "12px",
                borderRadius: "4px",
                border: `1px solid ${mp.ended_at ? enerbitColors.error.main : enerbitColors.primary[200]}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "nowrap",
                overflow: "hidden",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    minWidth: 0,
                    flex: 1,
                }}
            >
                <IconButton
                    size="small"
                    onClick={() => {
                        handleCopy(mp.measurement_point_id);
                    }}
                >
                    <ContentCopyIcon fontSize="small" color="primary" />
                </IconButton>
                {!mp.ended_at && (
                    <RadioButtonCheckedIcon fontSize="small" color="success" />
                )}
                <Box sx={{ flex: 1, minWidth: 0 }}>
                    <Typography
                        sx={{
                            color: enerbitColors.neutral.main,
                            fontSize: "12px",
                        }}
                    >
                        Punto de medida
                    </Typography>
                    <Typography
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: enerbitColors.primary.main,
                            fontWeight: 700,
                        }}
                    >
                        {mp.measurement_point_id}
                    </Typography>
                </Box>
            </Box>
            <IconButton
                onClick={() => {
                    goToMpDetail(mp.measurement_point_id);
                }}
                sx={{
                    backgroundColor: "#FF7705",
                    borderRadius: "12px",
                    height: "32px",
                    width: "32px",
                    ":hover": {
                        backgroundColor: "#FF7705",
                    },
                }}
            >
                <ArrowForwardIcon sx={{ color: "white" }} />
            </IconButton>
        </Box>
    );
};

export default MeasurementPointItem;
