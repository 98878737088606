import { create } from "zustand";
import { MeterGeneralInfo, MeterModel, MetersState } from "../models";
import { getMeterModelsService, getMeterService } from "../services";

export const useMetersState = create<MetersState>()((set) => ({
    loading: false,
    error: false,
    meters: [],
    meter: null,
    meterModels: null,
    currentPage: 0,
    totalMeters: 0,
    totalPages: 0,
    getMeterModels: async () => {
        try {
            const res = await getMeterModelsService();
            set({ meterModels: res.items });
        } catch (_error) {}
    },
    getMeter: async (id: string) => {
        set({ loading: true, error: false, meter: null });
        try {
            const res = await getMeterService(id);

            set({
                meter: {
                    id: res.id,
                    serial: res.serial,
                    brand: res.meter_model.brand.name,
                    model: res.meter_model.name,
                    communicationProtocol:
                        res.meter_model.communication_protocol_type.name,
                },
            });
        } catch (_error) {
            set({ error: true, meter: null });
        } finally {
            set({ loading: false });
        }
    },
    setMeter: (value: MeterGeneralInfo | null) => {
        set({ meter: value });
    },
    setMeterModels: (models: MeterModel[]) => {
        set({ meterModels: models });
    },
}));
