import { Box, Button, CustomAlert, esES, GridColDef } from "@enerbit/base";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import { useNavigate } from "react-router-dom";
import { DATE_TIME_FORMAT, meterStatusColors } from "../../../common";
import moment from "moment";
import StatusTag from "../../../components/container/status-tag";
import {
    Filters,
    IPaginatedResponse,
    Meter,
    MeterState,
} from "../../../models";
import { useState } from "react";
import useSWR from "swr";
import { searchMetersService } from "../../../services";
import { StyledDataGrid } from "../../../components/Table/StyledDataGrid";
import { CustomPagination } from "../../../components/Table/CustomPagination";
import MeterTableFilters from "./filters";
import { detailButtonProps } from "../../../styles/detail-button";

const initialFilters: Filters = { model_id: "", meter_serial: "" };

const MetersTable = () => {
    const [filters, setFilters] = useState<Filters>(initialFilters);
    const [tempFilters, setTempFilters] = useState<Filters>(filters);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const navigate = useNavigate();

    const { data, isLoading, error, mutate } = useSWR<
        IPaginatedResponse<Meter>
    >([currentPage, filters], (args) => {
        const [page, filter] = args as [number, Filters];
        return searchMetersService({ page, filters: filter });
    });

    const handlePagination = (
        _event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setCurrentPage(value - 1);
    };

    const handleTempFiltersChange = (key: keyof Filters, value: unknown) => {
        setTempFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleSearch = () => {
        setFilters(tempFilters);
        setCurrentPage(0);
        mutate();
    };

    const handleReset = async () => {
        setTempFilters(initialFilters);
        setFilters(initialFilters);
        setCurrentPage(0);
        mutate();
    };

    const columns: GridColDef[] = [
        {
            field: "serial",
            headerName: "Serial",
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: "model",
            headerName: "Modelo",
            flex: 1,
            renderCell: (params) => params.row.meter_model.name,
            sortable: false,
            filterable: false,
        },
        {
            field: "brand",
            headerName: "Marca",
            renderCell: (params) => params.row.meter_model.brand.name,
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: "installation_date",
            headerName: "Fecha de instalación",
            flex: 1,
            renderCell: (params) =>
                params.row.instalation_date
                    ? moment(params.row.instalation_date).format(
                          DATE_TIME_FORMAT,
                      )
                    : "Desconocida",
        },
        {
            field: "status",
            headerName: "Estado",
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <StatusTag
                    bgcolor={
                        meterStatusColors[params.row.state.name as MeterState]
                            .bgcolor
                    }
                    fontcolor={
                        meterStatusColors[params.row.state.name as MeterState]
                            .fontcolor
                    }
                >
                    {params.row.state.name}
                </StatusTag>
            ),
        },
        {
            field: "actions",
            headerName: "Detalle",
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    sx={detailButtonProps}
                    onClick={() => navigate(`/${params.row.id}`)}
                    size="small"
                >
                    Ver detalle
                </Button>
            ),
        },
    ];

    return (
        <Box>
            <MeterTableFilters
                filters={tempFilters}
                handleFilterChange={handleTempFiltersChange}
                handleReset={handleReset}
                handleSearch={handleSearch}
            />

            {isLoading && (
                <Box sx={{ my: 2 }}>
                    <TableSkeleton columns={6} rows={20} />
                </Box>
            )}
            {error && (
                <CustomAlert
                    text="Error listando medidores."
                    sx={{ my: 2 }}
                    severity="error"
                    onClose={() => {}}
                />
            )}
            {data && data.items.length === 0 && (
                <CustomAlert
                    sx={{ my: 2 }}
                    text="No se encontraron medidores."
                    severity="info"
                    onClose={() => {}}
                />
            )}
            {!isLoading && !error && data && (
                <>
                    <Box sx={{ height: 600, width: "100%", my: 1 }}>
                        <StyledDataGrid
                            sx={{
                                "& .MuiDataGrid-virtualScrollerContent": {
                                    height:
                                        data.items.length > 0
                                            ? "auto!important"
                                            : "100px!important",
                                },
                                "& .MuiDataGrid-row": {
                                    minHeight: "52px!important",
                                    py: 1,
                                },
                            }}
                            getRowHeight={() => "auto"}
                            disableVirtualization
                            paginationMode="server"
                            disableColumnMenu
                            rowSpacingType="border"
                            rows={data.items}
                            getRowId={(row) => row.id}
                            rowHeight={100}
                            columns={columns}
                            pageSize={10}
                            loading={isLoading}
                            disableSelectionOnClick
                            autoHeight
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            pagination
                            components={{
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                pagination: {
                                    count: data.pages,
                                    page: currentPage + 1,
                                    onChange: handlePagination,
                                },
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default MetersTable;
