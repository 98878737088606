import { EUnits, SchedulesStatus, AdaptedSchedules } from "../models";

export const UNITS_VALUES: Record<EUnits, number> = {
    [EUnits.Wh]: 1,
    [EUnits.kWh]: 0.001,
};

export const SCHEDULES_COLORS: Record<
    SchedulesStatus,
    Record<string, { border: string; background: string }>
> = {
    exists: { "0": { background: "#F6FEF9", border: "#CAEFDE" } },
    estimated: {
        "0": { border: "#FEC84B", background: "#FEEFC7" },
        "25": { border: "#FEC84B", background: "#FEEFC7" },
        "50": { border: "#FEC84B", background: "#FEDF89" },
        "75": { border: "#F79009", background: "#FDB022" },
        "100": { border: "#93370D", background: "#DC6803" },
    },
    missing: {
        "0": { border: "#FDA29B", background: "#FEE4E2" },
        "25": { border: "#FDA29B", background: "#FEE4E2" },
        "50": { border: "#F97066", background: "#FDA29B" },
        "75": { border: "#D92D20", background: "#F04438" },
        "100": { border: "#912018", background: "#B42318" },
    },
    future: { "0": { border: "#E4E7EC", background: "#F9FAFB" } },
};

export const MEASURES: {
    label: string;
    units: string;
    key: keyof AdaptedSchedules;
}[] = [
    {
        label: "Ener. Act. Import",
        units: "Wh",
        key: "active_energy_imported",
    },
    {
        label: "Ener. Act. export",
        units: "Wh",
        key: "active_energy_exported",
    },
    {
        label: "Ener. Reac. Export",
        units: "VArh",
        key: "reactive_energy_exported",
    },
    {
        label: "Ener. Reac. Import",
        units: "VArh",
        key: "reactive_energy_imported",
    },
];
