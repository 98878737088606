import { api } from "@enerbit/base";
import { DeviceMeterRelationship, IPaginatedResponse } from "../models";

export const getMeterDeviceRelationshipService = async ({
    page,
    meterId,
    size,
}: {
    meterId: string;
    page: number;
    size?: number;
}) => {
    try {
        const params: Record<string, unknown> = { page, size: size ?? 50 };
        const { data } = await api.get<
            IPaginatedResponse<DeviceMeterRelationship>
        >(
            `/electricity-supply-service/device/device-meters/relationship/meters/${meterId}/`,
            { params },
        );

        return data;
    } catch (_error) {
        throw new Error("ErrGettingMeterDeviceRelationships");
    }
};
