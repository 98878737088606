import { Box, Divider, enerbitColors, Grid, Typography } from "@enerbit/base";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StyledModal from "../../../components/container/styled-modal";
import { IMeterLastReport } from "../../../models";
import SectionContainer from "../../../components/container/section-container";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    lastReport: IMeterLastReport;
}

const accumulatesNames: Record<
    string,
    { label: string; units: string; key: keyof IMeterLastReport }
> = {
    energia_activa_exportada: {
        label: "Energía activa exportada",
        units: "Wh",
        key: "active_energy_exported",
    },
    energia_activa_importada: {
        label: "Energía activa importada",
        units: "Wh",
        key: "active_energy_imported",
    },
    energia_reactiva_exportada: {
        label: "Energía reactiva exportada",
        units: "VArh",
        key: "reactive_energy_exported",
    },
    energia_reactiva_importada: {
        label: "Energía reactiva importada",
        units: "VArh",
        key: "reactive_energy_imported",
    },
};

const lastReportData: {
    label: string;
    key: keyof IMeterLastReport;
    units: string;
}[] = [
    { label: "Voltaje L1", key: "voltage_l1n", units: "V" },
    { label: "Voltaje L2", key: "voltage_l2n", units: "V" },
    { label: "Voltaje L3", key: "voltage_l3n", units: "V" },
    { label: "Corriente L1", key: "current_l1", units: "A" },
    { label: "Corriente L2", key: "current_l2", units: "A" },
    { label: "Corriente L3", key: "current_l3", units: "A" },
    { label: "Factor de potencia L1", key: "power_factor_l1", units: "" },
    { label: "Factor de potencia L2", key: "power_factor_l2", units: "" },
    { label: "Factor de potencia L3", key: "power_factor_l3", units: "" },
];

const formattedNumber = (value: number) => {
    return value.toLocaleString("es-Es");
};

const MeasurementsOverviewModal = ({
    isOpen,
    handleClose,
    lastReport,
}: Props) => {
    return (
        <StyledModal
            open={isOpen}
            onClose={handleClose}
            title="Datos adicionales del medidor"
        >
            <>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    {Object.keys(accumulatesNames).map((key) => (
                        <Grid item key={key} xs={6}>
                            <Box
                                sx={{
                                    background:
                                        "linear-gradient(90deg, #F9FAFB 0%, #F9F6FF 100%)",
                                    padding: "12px",
                                    borderRadius: "8px",
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "#F2E8FF",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "44px",
                                        width: "44px",
                                        borderRadius: "50%",
                                    }}
                                >
                                    <AccessTimeIcon />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: enerbitColors.neutral.main,
                                            fontSize: "18px",
                                        }}
                                    >
                                        {accumulatesNames[key].label}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: enerbitColors.neutral[900],
                                            fontSize: "24px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {lastReport[accumulatesNames[key].key]}{" "}
                                        {accumulatesNames[key].units}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <SectionContainer sx={{ mt: 2 }}>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            color: enerbitColors.primary.main,
                            fontSize: "18px",
                        }}
                    >
                        Información
                    </Typography>
                    <Divider sx={{ my: 1, mx: "-12px" }} />
                    <Grid container columnSpacing={2} rowSpacing={2}>
                        {lastReportData.map((item) => (
                            <Grid item md={4} key={item.key}>
                                <Typography
                                    sx={{ color: "#525A6A", fontSize: "12px" }}
                                >
                                    {item.label}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#525A6A",
                                        fontSize: "16px",
                                        fontWeight: 700,
                                    }}
                                >
                                    {formattedNumber(+lastReport[item.key])}{" "}
                                    {item.units}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </SectionContainer>
            </>
        </StyledModal>
    );
};

export default MeasurementsOverviewModal;
