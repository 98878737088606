import {
    Box,
    ContentCopyIcon,
    enerbitColors,
    Grid,
    IconButton,
    Typography,
} from "@enerbit/base";
import { Multipliers } from "../../../models";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../common";
import StatusTag from "../../../components/container/status-tag";

interface Props {
    multiplier: Multipliers;
}

const MultiplierItem = ({ multiplier }: Props) => {
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        // setTimeout(() => {}, 2000);
    };
    return (
        <Box
            sx={{
                padding: "12px",
                border: `1px solid ${multiplier.end ? enerbitColors.error.main : enerbitColors.primary[200]}`,
                borderRadius: "4px",
            }}
        >
            <Grid container columnSpacing={2}>
                <Grid item xs={2.4}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleCopy(`${multiplier.voltage_multiplier}`);
                            }}
                        >
                            <ContentCopyIcon fontSize="small" color="primary" />
                        </IconButton>
                        <Box>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral.main,
                                    fontSize: "12px",
                                }}
                            >
                                Multiplicador de voltaje
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: enerbitColors.neutral[900],
                                }}
                            >
                                {multiplier.voltage_multiplier}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2.4}>
                    <Box>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral.main,
                                fontSize: "12px",
                            }}
                        >
                            Multiplicador de corriente
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                color: enerbitColors.neutral[900],
                            }}
                        >
                            {multiplier.current_multiplier}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.4}>
                    <Box>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral.main,
                                fontSize: "12px",
                            }}
                        >
                            Desde
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                color: enerbitColors.neutral[900],
                            }}
                        >
                            {moment(multiplier.start).format(DATE_TIME_FORMAT)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.4}>
                    <Box>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral.main,
                                fontSize: "12px",
                            }}
                        >
                            Hasta
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                color: enerbitColors.neutral[900],
                            }}
                        >
                            {multiplier.end
                                ? moment(multiplier.start).format(
                                      DATE_TIME_FORMAT,
                                  )
                                : "Actualidad"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2.4} alignContent={"center"}>
                    <StatusTag
                        bgcolor={
                            multiplier.end
                                ? enerbitColors.error[100]
                                : enerbitColors.success[100]
                        }
                        fontcolor={
                            multiplier.end
                                ? enerbitColors.error.main
                                : enerbitColors.success.main
                        }
                    >
                        {multiplier.end ? "Vencido" : "Activo"}
                    </StatusTag>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MultiplierItem;
