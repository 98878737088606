import { Controller, useForm } from "react-hook-form";
import StyledModal from "../../../components/container/styled-modal";
import {
    AdapterMoment,
    CustomAlert,
    DateTimePicker,
    FormControl,
    Grid,
    InputLabel,
    LoadingButton,
    LocalizationProvider,
    MenuItem,
    Select,
} from "@enerbit/base";
import { DATE_TIME_INPUT_FORMAT } from "../../../common";
import moment from "moment";
import { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { RestoreSchedulesType } from "../../../models";
import {
    restoreSchedulesBySmartbit,
    restoresMeterSchedules,
} from "../../../services";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
}

interface RestoreDataForm {
    since: string;
    until: string;
    restore_type: string;
}

const schema = z.object({
    since: z.string().min(1, "Este campo es obligatorio"),
    until: z.string().min(1, "Este campo es obligatorio"),
    restore_type: z.string(),
});

const RestoreModal = ({ isOpen, handleClose }: Props) => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors: formErrors },
    } = useForm<RestoreDataForm>({
        defaultValues: {
            since: "",
            until: "",
            restore_type: RestoreSchedulesType.METER,
        },
        resolver: zodResolver(schema),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);

    const { id } = useParams<{ id: string }>();

    const onSubmit = async (data: RestoreDataForm) => {
        if (!id) return;
        setError(undefined);
        setSuccess(false);
        setLoading(true);
        try {
            const since = moment(data.since)
                .utcOffset(-5)
                .format("YYYY-MM-DDTHH:mm:ssZ");
            const until = moment(data.until)
                .utcOffset(-5)
                .format("YYYY-MM-DDTHH:mm:ssZ");

            if (data.restore_type === RestoreSchedulesType.METER) {
                await restoresMeterSchedules({ meterId: id, since, until });
            } else {
                await restoreSchedulesBySmartbit({ meterId: id, since, until });
            }
            setSuccess(true);
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        } catch (error: any) {
            if (error.status === 403) {
                setError(
                    "Error enviando comando de recuperación. Ya existe un comando activo.",
                );
            } else {
                setError(
                    "Error enviando comando de recuperación. Intenta de nuevo.",
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const onClose = () => {
        reset();
        setError(undefined);
        setSuccess(false);
        setLoading(false);
        handleClose();
    };

    return (
        <StyledModal
            open={isOpen}
            onClose={onClose}
            title="Recuperación de datos"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="restore_type"
                            render={({ field }) => (
                                <>
                                    <InputLabel>Recuperar por:</InputLabel>
                                    <FormControl fullWidth>
                                        <Select fullWidth {...field}>
                                            <MenuItem
                                                value={
                                                    RestoreSchedulesType.METER
                                                }
                                            >
                                                Medidor
                                            </MenuItem>
                                            <MenuItem
                                                value={
                                                    RestoreSchedulesType.SMARTBIT
                                                }
                                            >
                                                smartBit
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            control={control}
                            name="since"
                            render={({ field }) => (
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <InputLabel>Desde</InputLabel>
                                    <FormControl fullWidth>
                                        <DateTimePicker
                                            {...field}
                                            format={DATE_TIME_INPUT_FORMAT}
                                            value={
                                                field.value
                                                    ? moment(field.value)
                                                    : null
                                            }
                                            maxDateTime={moment()}
                                            onChange={(value) =>
                                                field.onChange(
                                                    value?.toISOString() || "",
                                                )
                                            }
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!formErrors.since,
                                                    helperText:
                                                        !!formErrors.since &&
                                                        formErrors.since
                                                            .message,
                                                    // sx: datePickerStyles,
                                                },
                                                popper: {
                                                    placement: "left",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            control={control}
                            name="until"
                            render={({ field }) => (
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <InputLabel>Hasta</InputLabel>
                                    <FormControl fullWidth>
                                        <DateTimePicker
                                            {...field}
                                            format={DATE_TIME_INPUT_FORMAT}
                                            value={
                                                field.value
                                                    ? moment(field.value)
                                                    : null
                                            }
                                            maxDateTime={moment()}
                                            onChange={(value) =>
                                                field.onChange(
                                                    value?.toISOString() || "",
                                                )
                                            }
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!formErrors.until,
                                                    helperText:
                                                        !!formErrors.until &&
                                                        formErrors.until
                                                            .message,
                                                    // sx: datePickerStyles,
                                                },
                                                popper: {
                                                    placement: "right",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    {error && (
                        <Grid item xs={12}>
                            <CustomAlert
                                text={error}
                                severity="error"
                                onClose={() => {}}
                            />
                        </Grid>
                    )}
                    {success && (
                        <Grid item xs={12}>
                            <CustomAlert
                                text="Comando de recuperación enviado"
                                severity="success"
                                onClose={() => {}}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            color="secondary"
                            type="submit"
                            loading={loading}
                        >
                            Recuperar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </StyledModal>
    );
};

export default RestoreModal;
