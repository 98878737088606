import {
    Box,
    enerbitColors,
    ContentCopyIcon,
    Typography,
    ArrowForwardIcon,
    IconButton,
} from "@enerbit/base";
import { Handle, Position } from "@xyflow/react";
import { useNavigate } from "react-router-dom";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function MeterNode({ data, width }: any) {
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        // setTimeout(() => {}, 2000);
    };
    const navigate = useNavigate();

    const goToMeterDetail = (id: string) => {
        navigate(`/${id}`);
    };
    return (
        <>
            <Box
                sx={{
                    width: width,
                    padding: "12px",
                    borderRadius: "4px",
                    border: `1px solid ${enerbitColors.primary[200]}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        minWidth: 0,
                        flex: 1,
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={() => {
                            handleCopy(data.serial.split("/")[0]);
                        }}
                    >
                        <ContentCopyIcon fontSize="small" color="primary" />
                    </IconButton>
                    <Box
                        sx={{
                            minWidth: 0,
                            flex: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: enerbitColors.neutral[700],
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            Serial medidor/modelo
                        </Typography>
                        <Typography
                            sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: enerbitColors.primary.main,
                                fontWeight: 700,
                            }}
                        >
                            {data.serial}
                        </Typography>
                    </Box>
                </Box>
                <IconButton
                    sx={{
                        backgroundColor: "#FF7705",
                        borderRadius: "12px",
                        height: "32px",
                        width: "32px",
                        ":hover": {
                            backgroundColor: "#FF7705",
                        },
                    }}
                    onClick={() => {
                        goToMeterDetail(data.id);
                    }}
                >
                    <ArrowForwardIcon sx={{ color: "white" }} />
                </IconButton>
            </Box>
            <Handle type="target" position={Position.Left} id="b" />
        </>
    );
}

export default MeterNode;
