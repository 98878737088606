import moment from "moment";
import { AdaptedSchedules, SchedulesStatus } from "../models/usages";
import { SCHEDULES_COLORS } from "../common";

export const formatUsages = (schedules: AdaptedSchedules[]) => {
    // 1. Agrupar por días
    const groupedByDate = groupUsagesByDate(schedules);

    // 2. Verificar intervalos faltantes
    const results = getIntervalStatus(groupedByDate);

    return results;
};

const groupUsagesByDate = (data: AdaptedSchedules[]) => {
    return data.reduce(
        (acc, measurement) => {
            const date = moment(measurement.start).format("YYYY-MM-DD");
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(measurement);
            return acc;
        },
        {} as Record<string, AdaptedSchedules[]>,
    );
};

const getIntervalStatus = (usages: Record<string, AdaptedSchedules[]>) => {
    const currentTime = moment();
    const result: Record<string, Record<string, AdaptedSchedules[]>> = {};

    for (const [date, measurements] of Object.entries(usages)) {
        const intervalsFound: Set<string> = new Set();

        // Marcamos todos los intervalos encontrados y los categorizamos
        measurements.forEach((measurement) => {
            const startTime = moment(measurement.start);
            const intervalLabel = startTime.format("HH:mm");

            intervalsFound.add(intervalLabel);

            const hasNullValues =
                measurement.active_energy_imported === null ||
                measurement.reactive_energy_imported === null ||
                measurement.active_energy_exported === null ||
                measurement.reactive_energy_exported === null;

            if (measurement.is_estimated) {
                measurement.status = SchedulesStatus.ESTIMATED;
            } else if (startTime.isSameOrAfter(currentTime, "hour")) {
                measurement.status = SchedulesStatus.FUTURE;
            } else if (hasNullValues) {
                measurement.status = SchedulesStatus.MISSING;
            } else {
                measurement.status = SchedulesStatus.EXISTS;
            }
        });

        // Generamos todos los intervalos de 15 minutos en el día
        const allIntervals = generateIntervalsForDay(date);

        // Calculamos los intervalos faltantes y los marcamos
        allIntervals.forEach((interval) => {
            if (!intervalsFound.has(interval)) {
                const missingMeasurement: AdaptedSchedules = {
                    active_energy_imported: null,
                    reactive_energy_imported: null,
                    active_energy_exported: null,
                    reactive_energy_exported: null,
                    start: `${date}T${interval}-05:00`,
                    end: "",
                    is_estimated: false,
                };
                if (
                    moment(`${date}T${interval}-05:00`).isSameOrAfter(
                        currentTime,
                        "minute",
                    )
                ) {
                    missingMeasurement["status"] = SchedulesStatus.FUTURE;
                } else {
                    missingMeasurement["status"] = SchedulesStatus.MISSING;
                }

                measurements.push(missingMeasurement);
            }
        });

        // Ordenar mediciones por `start`
        measurements.sort((a, b) => moment(a.start).diff(moment(b.start)));

        // Agrupar intervalos por hora dentro del día
        result[date] = groupByHour(measurements);
    }

    return result;
};

const generateIntervalsForDay = (date: string): string[] => {
    const intervals: string[] = [];
    const startOfDay = moment(`${date}T00:00`);
    const endOfDay = moment(`${date}T23:59`);

    let current = startOfDay.clone();
    while (current.isBefore(endOfDay)) {
        intervals.push(current.format("HH:mm"));
        current.add(15, "minutes");
    }

    return intervals;
};

const groupByHour = (measurements: AdaptedSchedules[]) => {
    const grouped = measurements.reduce(
        (acc, measurement) => {
            const hour = moment(measurement.start).format("HH");
            if (!acc[hour]) {
                acc[hour] = [];
            }
            acc[hour].push(measurement);
            return acc;
        },
        {} as Record<string, AdaptedSchedules[]>,
    );

    Object.values(grouped).forEach((list) => {
        list.sort((a, b) => moment(a.start).diff(moment(b.start)));
    });

    const orderedKeys = Object.keys(grouped).sort();
    const orderedGrouped: Record<string, AdaptedSchedules[]> = {};
    orderedKeys.forEach((key) => {
        orderedGrouped[key] = grouped[key];
    });

    return orderedGrouped;
};

const getPercentageColor = (
    data: AdaptedSchedules[],
    status: SchedulesStatus,
) => {
    const total = data.filter((usage) => usage.status === status).length;
    const percentage = (total / data.length) * 100;

    return SCHEDULES_COLORS[status][percentage.toString()];
};

export const getStatusColor = (data: AdaptedSchedules[]) => {
    if (data.some((d) => d.status === SchedulesStatus.MISSING)) {
        return getPercentageColor(data, SchedulesStatus.MISSING);
    }

    if (data.some((d) => d.status === SchedulesStatus.ESTIMATED)) {
        return getPercentageColor(data, SchedulesStatus.ESTIMATED);
    }

    if (data.some((d) => d.status === SchedulesStatus.FUTURE)) {
        return SCHEDULES_COLORS.future["0"];
    }

    return SCHEDULES_COLORS.exists["0"];
};
