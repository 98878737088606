import {
    Box,
    CustomAlert,
    esES,
    GridColDef,
    LoadingButton,
    Typography,
} from "@enerbit/base";
import { useState } from "react";
import { EVENT_TYPES_TO_NAMES } from "../../../common";
import {
    AdaptedEvents,
    Filters,
    IPaginatedResponse,
    MeterModel,
} from "../../../models";
import { getEventCategory } from "../../../utils/events";
import StatusTag from "../../../components/container/status-tag";
import useSWR from "swr";
import {
    fetchMeterEvents,
    getMeterModelsService,
    searchMetersService,
} from "../../../services";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import { StyledDataGrid } from "../../../components/Table/StyledDataGrid";
import { CustomPagination } from "../../../components/Table/CustomPagination";
import { useMetersState } from "../../../store";
import { detailButtonProps } from "../../../styles/detail-button";
import { useNavigate } from "react-router-dom";
import EventsFilters from "./events-filter";

const initialFilters: Filters = {
    meter_serial: "",
    event_types: [],
};

const EventsTable = () => {
    const { meterModels, setMeterModels } = useMetersState((state) => state);
    const [filters, setFilters] = useState<Filters>(initialFilters);
    const [tempFilters, setTempFilters] = useState<Filters>(filters);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const navigate = useNavigate();

    const { data, error, isLoading, mutate } = useSWR<
        IPaginatedResponse<AdaptedEvents>
    >(["/odin/meters/events", currentPage, filters], async (args) => {
        const [url, page, filter] = args as [string, number, Filters];
        let models: MeterModel[] = [];
        if (!meterModels) {
            const tempModels = await getMeterModelsService();
            models = tempModels.items;
            setMeterModels(tempModels.items);
        }
        return fetchMeterEvents({
            url,
            page,
            filters: filter,
            models: meterModels ?? models,
        });
    });

    const handlePagination = (
        _event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setCurrentPage(value - 1);
    };

    const handleTempFiltersChange = (key: keyof Filters, value: unknown) => {
        setTempFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleSearch = () => {
        setFilters(tempFilters);
        setCurrentPage(0);
        mutate();
    };

    const handleReset = async () => {
        setTempFilters(initialFilters);
        setFilters(initialFilters);
        setCurrentPage(0);
        mutate();
    };

    const renderStatus = (event: string) => {
        const { bgcolor, fontColor, label } = getEventCategory(event);

        return (
            <StatusTag bgcolor={bgcolor} fontcolor={fontColor}>
                {label}
            </StatusTag>
        );
    };

    const columns: GridColDef[] = [
        {
            field: "serial",
            headerName: "Serial",
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: "model",
            headerName: "Código modelo",
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: "event",
            headerName: "Evento",
            flex: 1,
            renderCell: (params) => (
                <Box
                    sx={{
                        px: "12px",
                        py: "4px",
                        backgroundColor: "#F6F6F8",
                        borderRadius: "8px",
                        display: "inline-block",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            color: "#49494C",
                        }}
                    >
                        {EVENT_TYPES_TO_NAMES[params.row.event] ??
                            "Desconocido"}
                    </Typography>
                </Box>
            ),
            sortable: false,
            filterable: false,
        },
        {
            field: "event_category",
            headerName: "Tipon de evento",
            flex: 1,
            renderCell: (params) => renderStatus(params.row.event),
            sortable: false,
            filterable: false,
        },
        {
            field: "eventDate",
            headerName: "Fecha",
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: "actions",
            headerName: "Acciones",
            renderCell: (params) => {
                const [loading, setLoading] = useState<boolean>(false);

                const goToMeterDetail = async () => {
                    setLoading(true);
                    try {
                        const meter = await searchMetersService({
                            page: 0,
                            filters: { meter_serial: params.row.serial },
                        });

                        if (meter.items.length === 0) return;
                        navigate(`/${meter.items[0].id}`);
                    } catch (_error) {
                    } finally {
                        setLoading(false);
                    }
                };
                return (
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={detailButtonProps}
                        onClick={goToMeterDetail}
                        loading={loading}
                    >
                        Historial de eventos
                    </LoadingButton>
                );
            },
            flex: 1,
            sortable: false,
            filterable: false,
        },
    ];

    return (
        <Box>
            <Box sx={{ my: 2 }}>
                <EventsFilters
                    filters={tempFilters}
                    handleFilterChange={handleTempFiltersChange}
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    // handleEventTypesChange={handleEventTypesChange}
                />
            </Box>
            {isLoading && (
                <Box sx={{ my: 2 }}>
                    <TableSkeleton columns={6} rows={20} />
                </Box>
            )}
            {error && (
                <CustomAlert
                    text="Error listando eventos."
                    sx={{ my: 2 }}
                    severity="error"
                    onClose={() => {}}
                />
            )}
            {data && data.items.length === 0 && (
                <CustomAlert
                    sx={{ my: 2 }}
                    text="No se encontraron eventos."
                    severity="info"
                    onClose={() => {}}
                />
            )}
            {!isLoading && !error && data && (
                <>
                    <Box sx={{ height: 600, width: "100%", my: 1 }}>
                        <StyledDataGrid
                            sx={{
                                "& .MuiDataGrid-virtualScrollerContent": {
                                    height:
                                        data.items.length > 0
                                            ? "auto!important"
                                            : "100px!important",
                                },
                                "& .MuiDataGrid-row": {
                                    minHeight: "52px!important",
                                    py: 1,
                                },
                            }}
                            getRowHeight={() => "auto"}
                            disableVirtualization
                            paginationMode="server"
                            disableColumnMenu
                            rowSpacingType="border"
                            rows={data.items}
                            getRowId={(row) => row.id}
                            rowHeight={100}
                            columns={columns}
                            pageSize={10}
                            loading={isLoading}
                            disableSelectionOnClick
                            autoHeight
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            pagination
                            components={{
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                pagination: {
                                    count: data.pages,
                                    page: currentPage + 1,
                                    onChange: handlePagination,
                                },
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default EventsTable;
