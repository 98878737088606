import { api } from "@enerbit/base";
import {
    AdaptedEvents,
    EventTypes,
    Filters,
    IEvents,
    IPaginatedResponse,
    ListMeterEventsParams,
    SearchEventsParams,
} from "../models";
import moment from "moment";
import { DATE_TIME_FORMAT, eventValues } from "../common";

export const getEventsService = async ({
    serial,
    page,
}: ListMeterEventsParams) => {
    try {
        const params: Record<string, unknown> = {
            page,
            size: 10,
            serial,
            event_types: eventValues.join(","),
        };

        const res = await api.get<IPaginatedResponse<IEvents>>(
            "/odin/meters/events",
            { params },
        );

        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingEvents");
    }
};

export const getEventTypesService = async () => {
    try {
        const { data } = await api.get<EventTypes[]>(
            "/odin/meters/event-types",
        );
        return data;
    } catch (_error) {
        throw new Error("ErrGettingEventTypes");
    }
};

export const fetchMeterEvents = async ({
    url,
    page,
    filters,
    models,
}: SearchEventsParams) => {
    const params: Filters = { page };

    if (filters) {
        const typedFilters = filters as { event_types: string[] };
        params["event_types"] =
            typedFilters.event_types.length > 0
                ? typedFilters.event_types.join(",")
                : eventValues.join(",");
    }

    const res = await api.get<IPaginatedResponse<IEvents>>(url, { params });
    // const models = await getMeterModelsService();

    return {
        ...res.data,
        items: res.data.items.map((ev) => {
            const parsedEvent: AdaptedEvents = {
                id: ev.id,
                event: ev.event_types.event,
                model:
                    models.find((model) => model.code === ev.meter_model)
                        ?.name ?? "Desconocido",
                eventDate: moment(ev.time_sender).format(DATE_TIME_FORMAT),
                serial: ev.meter_serial,
            };
            return parsedEvent;
        }),
    };
};
